<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-center">{{ exam.title }}</h1>
        <!-- sorular - başlangıç -->
        <div v-for="(question, qIndex) in questions" :key="qIndex">
          <div class="d-flex aling-items-center justify-content-start question">
            <span>{{qIndex + 1}}&nbsp;-&nbsp;</span>
            <span v-html="`${question.question}`"></span>
          </div>
          <ul class="list-group mb-3">
            <li
              v-for="(answer, aIndex) in question.answers"
              :key="aIndex"
              class="list-group-item"
              :class="[`answer-${qIndex}`, `answer-${qIndex}-${aIndex}`]"
              style="cursor: pointer;"
              @click="
                giveAnswer(
                  answer,
                  question.answers,
                  $event,
                  qIndex,
                  questions.length
                )
              "
            >
              {{ abc[aIndex] }} - {{ answer.answer }}
            </li>
          </ul>
        </div>
        <!-- sorular - bitiş -->
        <!-- cevaplar tablo - başlangıç -->
        <div class="my-5">
          <h3 class="d-flex justify-content-between aling-items-center">
            <strong>CEVAPLARINIZ</strong>
            <span>ALDIĞINIZ PUAN - {{ puan }}</span>
          </h3>
          <div class="row">
            <div
              class="col col-md-2 text-center border border-light p-2"
              :class="`col-question-${cIndex}`"
              v-for="(question, cIndex) in questions"
              :key="cIndex"
            >
              {{ cIndex + 1 }}
            </div>
          </div>
        </div>
        <!-- cevaplar tablosu -bitiş -->
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalStepper from "vue-stepper";
export default {
  props: ["item"],
  components: {
    HorizontalStepper,
  },
  data() {
    return {
      exam: [],
      questions: [],
      userAnswers: [],
      abc: ["A", "B", "C", "D", "E", "F"],
      puan: 0,
    };
  },
  mounted() {
    this.exam = JSON.parse(this.item);
    this.questions = JSON.parse(this.exam.questions);
  },
  methods: {
    giveAnswer(answer, answers, event, qIndex, questions) {
      // event.target.classList.remove("btn-default");
      event.target.classList.add("text-white");
      if (answer.correct) {
        event.target.classList.add("bg-success");
        $(`.col-question-${qIndex}`).addClass("bg-success text-white");
      } else {
        event.target.classList.add("bg-danger");
        answers.map((item, index) => {
          if (item.correct) {
            $(`.answer-${qIndex}-${index}`).addClass("bg-success text-white");
          }
        });
         $(`.col-question-${qIndex}`).addClass("bg-danger text-white");
      }
      this.userAnswers[qIndex] = answer.correct;
      this.calculate(questions);
      $(`.answer-${qIndex}`).addClass("disabled");
    },
    calculate(questions) {
      this.puan =
        this.userAnswers.filter((item) => item).length * (100 / questions);
    },
  },
};
</script>

<style scoped>
  p {
    margin: 0;
    padding: 0;
  }
  .question{
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
</style>